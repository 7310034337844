// https://jxs4138.uta.cloud/about
import Header from "../components/Header"
import '../styles/header.css'

function About() {
  return (
    <>
        <Header/>
        <main>
            <div class="intro">
                <div class="about-me">
                <div class="about-me-text">
                    <h2>About Us</h2>
                    <p>This is a URM Application that helps candidates to apply for PHD Studies, Postdoc positions, and faculty positions.</p>
                    <p>You can login or Register to access this list.</p>
                    <p>They can also search for universities based on their preferences and requirements.</p>
                    <p>This website helps DEI Officers identify colleges and universities where diversity is lacking.</p>
                </div>
            </div>
            </div>
        </main>
    </>
  ); 
}

export default About;
