// https://jxs4138.uta.cloud/forgot-password
import Header from '../components/Header';
import '../styles/header.css'
import '../styles/forgotpassword.css'
import { Link } from "react-router-dom"

function ForgotPassword() {
  return (
    <>
        <Header/>
        <main>
        <div class="intro">
        <h2 class="centerCont">FORGOT PASSWORD</h2>
        <div class="f-container">
        <label for="uname"><b>Email</b></label>
        <input type="text" placeholder="Enter your Email Address" name="uname" required/>
        <button type="submit">Request Password Reset</button>
        <Link to='/login'><a href="Login.html" id="forgotPasswordLink">Remember Password?</a></Link>
        </div>
        </div>
        </main>
    </>
  ); 
}

export default ForgotPassword;
