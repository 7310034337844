// https://jxs4138.uta.cloud/edit-profile
import CandidateHeader from '../components/CandidateHeader';
import '../styles/header.css'
import '../styles/Candidatedashboard.css'
import '../styles/profile-edit.css'
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'

function EditProfile() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Email = queryParams.get('email');
  return (
    <>
        <CandidateHeader Email={Email}/>
        <div class="p-container">
            <h1>Edit Profile</h1>    
            <form>
                <label for="firstName">First Name:</label>
                <input type="text" id="firstName" name="firstName" placeholder="Your First Name"/>
                <label for="lastName">Last Name:</label>
                <input type="text" id="lastName" name="lastName" placeholder="Your Last Name"/>
                <label for="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Your Email Address"/>
                <label for="age">Age:</label>
                <input type="text" id="age" name="age" placeholder="Your Age"/>
                <label for="gender">Gender:</label>
                <select  required name="gender">
                <option hidden value=' ' disabled selected >Gender</option>
                <option >Male</option>
                <option>Female</option>
                <option>Other</option>
                </select>
                <label for="city">City:</label>
                <input type="text" id="city" name="city" placeholder="Your City name"/>
                <label for="study">Field of study</label>
                <input type="text" id="study" name="study" placeholder="Your field of study"/>
                <label for="education">Education</label>
                <input type="text" id="education" name="education" placeholder="Education"/>
                <label for="resume">Upload Resume:</label>
                <input type="file" id="resume" name="resume" accept=".pdf,.doc,.docx" required/>
                <input type="submit" value="Save Changes"/>
            </form>
        </div>
    </>
  ); 
}

export default EditProfile;
