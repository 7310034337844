// https://jxs4138.uta.cloud/register
import Header from "../components/Header"
import '../styles/header.css'
import '../styles/register.css'
import { Link } from "react-router-dom"
import { useState } from "react"
import { useNavigate } from "react-router-dom";

function Register() {
  const navigate = useNavigate();
  const [role, setRole] = useState("")
  const handleRole = (e) => {
    alert(e)
    setRole(e.target.value);
  }
  const academia = (e) => {
      navigate('/academia-register')
  }
  const candidate = (e) => {
    navigate('/candidate-register')
}
const deiOfficer = (e) => {
  navigate('/dei-register')
}
  return (
    <>
        <Header/>
        <main>
            <div class="intro">
                <form class="r-container" method="post"> 
                <h1>Registration Form</h1>
                <label for="role">
                Select the Role that best describes you:
                </label>
                <button class="registerbtn" onClick={academia}>Academia</button>
                <button class="registerbtn" onClick={candidate}>Candidate</button>
                <button class="registerbtn" onClick={deiOfficer}>DEI Officer</button>
            </form>
            <div class="container signin">
                <p>Already have an account? <Link to='/login'><a >Sign in</a></Link>.</p>
            </div>
    </div>
  </main>
    </>
  ); 
}

export default Register;
