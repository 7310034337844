// https://jxs4138.uta.cloud/dei-officer
import DEIHeader from '../components/DeiOfficerHeader';
import '../styles/header.css'
import '../styles/Candidatedashboard.css'
import '../styles/deiofficer.css'
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'

const TableComponentposting = ({ data }) => {
    return (
      <div >
        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Title</th>
              <th style={{ width: '30%' }}>Role</th>
              <th style={{ width: '50%' }}>Description</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.Title}</td>
                <td>{item.Role}</td>
                <td>{item.Description}</td>
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
  
    );
  };

  const TableComponentJoblist = ({ data }) => {
    return (
      <div >
        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Title</th>
              <th style={{ width: '20%' }}>Role</th>
              <th style={{ width: '20%' }}>Description</th>
              <th style={{ width: '40%' }}>Approve/Reject</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.Title}</td>
                <td>{item.Role}</td>
                <td>{item.Description}</td>
                <td><button>Approve</button><br></br><button>Reject</button></td>
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
  
    );
  };
  

function DEIOfficer() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const Email = queryParams.get('email');
    const [error, setError] = useState('')
    const [responseData, setResponseData] = useState([]);
    const [responseDatajobs, setResponseDatajobs] = useState([]);
    const [responseDatagoals, setResponseDatagoals] = useState([]);
    var deiPostings = async (e) => {
        console.log(Email);
        let config = { method: 'POST', Email: Email}
    
    try {
        await axios
           .post('https://mxp2278.uta.cloud/demo/dei_officer_job_postings_meet_goals.php', config)
          .then((response) => {
            console.log(response.data);
           // storeData=response.data;
           //setResponseData(response.data);
           const dataArray = Object.values(response.data); // Convert response.data into an array
                setResponseData(dataArray);
            //  <TableComponent data={Object.values(response.data)} />
            
          })
          .catch((error) => {
            console.log('no authorization', error)
          })
      } catch (error) {
        setTimeout(() => {
          setError('Invalid credentials...')
        }, 10)
      }
    }

    var deijobs = async (e) => {
      console.log(Email);
      let config = { method: 'POST', Email: Email}
  
  try {
      await axios
         .post('https://mxp2278.uta.cloud/demo/dei_officer_job_postings_for_approval.php', config)
        .then((response) => {
          console.log(response.data);
         // storeData=response.data;
         //setResponseData(response.data);
         const dataArray1 = Object.values(response.data); // Convert response.data into an array
              setResponseDatajobs(dataArray1);
          //  <TableComponent data={Object.values(response.data)} />
          
        })
        .catch((error) => {
          console.log('no authorization', error)
        })
    } catch (error) {
      setTimeout(() => {
        setError('Invalid credentials...')
      }, 10)
    }
  }

  var deigoals = async (e) => {
    console.log(Email);
    //console.log("JOB posting");
    let config = { method: 'POST', Email: Email}
  
  try {
    await axios
       .post('https://mxp2278.uta.cloud/demo/dei_officer_goals.php', config)
      .then((response) => {
        console.log(response.data);
        
       // storeData=response.data;
      //  //setResponseData(response.data);
       const dataArray2 = Object.values(response.data); // Convert response.data into an array
            setResponseDatagoals(dataArray2);
            console.log("RA")
            console.log(responseDatagoals);
      //       console.log('************');
            //console.log(responseDatadesc[0].AboutUs);
        //  <TableComponent data={Object.values(response.data)} />
        
      })
      .catch((error) => {
        console.log('no authorization', error)
      })
  } catch (error) {
    setTimeout(() => {
      setError('Invalid credentials...')
    }, 10)
  }
  }
  
  const ExampleComponent = ({ data }) => {
    const [responseDatadesc, setResponseDatadesc] = useState(data);
    return (
      
      <div>
        
        <h2>DEI Goals and Initiatives</h2>
        <ul>
          {responseDatadesc.map((item, index) => (
            <li key={index}>{item.Goal}</li>
          ))}
        </ul>
      </div>
    );
  };
    useEffect(() => {
        // Your function will be executed when the component is mounted
        deiPostings();
        deijobs();
        deigoals();
      }, []);

  return (
    <>
        <DEIHeader Email={Email}/>
        <main>
            {/* <p class="heading" style={{marginLeft:"48px"}}>DEI goals and initiatives</p> */}
            <div class="div1">
    <p class="content">
   <ExampleComponent data={responseDatagoals}/>
      </p></div>
    <br/><br/>
    <div class="div2">
        <div>
            <p class="heading">Postings that meet DEI Criteria</p>
            <TableComponentposting data={responseData} />
        </div>
        
    </div>
    <div class="table-container">
        <p class="heading">New Job Postings for Approval</p>
        
        <TableComponentJoblist data={responseDatajobs} />
    </div>
  </main>
    </>
  ); 
}

export default DEIOfficer;
