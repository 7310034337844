import BookmarkHeader from '../components/BookmarkHeader';
import '../styles/header.css'
import '../styles/Candidatedashboard.css'
import '../styles/profile-edit.css'
import { useLocation } from 'react-router-dom';
import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from "react-router-dom";


function NewJobPost() {
  const location = useLocation();
  const [error, setError] = useState('')
  const queryParams = new URLSearchParams(location.search);
  const Email = queryParams.get('email');
  console.log(Email);
  const [Position, setPosition] = useState('')
  const [Title, setTitle] = useState('')
  const [Description, setDescription] = useState('')
  const [Location, setLocation] = useState('')
  const handlePositionChange = (e) => {
    setPosition(e.target.value);
  };

  var postHandle = async (e) => {
    let config = { method: 'POST', Email: Email, Position: Position, Title: Title, Description: Description, Location : Location}

try {
    await axios
       .post('https://mxp2278.uta.cloud/demo/add_job_posting.php', config)
      .then((response) => {
        console.log(response.data);
        
        // const dataString = response.data.toString();
        // const startIndex = dataString.indexOf('{"userData":{');
        // const endIndex = dataString.lastIndexOf('}') + 1;
        // const userData = JSON.parse(dataString.substring(startIndex, endIndex)).userData;
        // const role = userData.role;
        
        //setLogin(true);
        if (response.data['status']==1){
          console.log("Job Entry Successful");
          alert('Job Entry Successful');
          window.location.reload();
        }
        if (response.data['status']==0){
          alert('Job Entry Failed. Try Again!');
          console.log("Job Posting failed");
        }
      })
      .catch((error) => {
        console.log('no authorization', error)
      })
  } catch (error) {
    setTimeout(() => {
      setError('Invalid credentials...')
    }, 10)
  }
}

  return (
    <>
        <BookmarkHeader Email={Email} />
        <main>
        <div class="container" style={{width:"50%", margin:"auto"}}>
            <h1>Post a new Job</h1>    
            <label for="post">
              Select the type of the post:
              <select id="post" name="post" onChange={handlePositionChange}>
                  <option value="phd">PhD</option>
                  <option value="PostDoc">PostDoc</option>
                  <option value="Faculty">Faculty</option>
              </select>
            </label>
            <label for="title">Enter the title of the Job</label>
            <input type="text" placeholder="Enter the title" name="title" id="title" required
            onChange={(e) => setTitle(e.target.value)}/>
            <label for="title">Enter the location of Job</label>
            <input type="text" placeholder="Enter the location" name="location" id="location" required
            onChange={(e) => setLocation(e.target.value)}/>
            <label for="desc">Enter the description of the Job</label>
            <input type="text" placeholder="Enter the description" name="desc" id="desc" required
            onChange={(e) => setDescription(e.target.value)}/>            
            <button type="submit" class="postbutton" onClick={postHandle}>Post</button>
        </div>
  </main>
    </>
  ); 
}

export default NewJobPost;
