// https://jxs4138.uta.cloud/candidate-register

import Header from "../components/Header"
import '../styles/header.css'
import '../styles/Candidatedashboard.css'
import '../styles/profile-edit.css'
import { Link } from 'react-router-dom';
import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function CandidateRegister() {
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [FirstName, setFirstName] = useState('')
  const [LastName, setLastName] = useState('')
    const [Email, setEmail] = useState('')
    const [Password, setPassword] = useState('')
    const [Role, setRole] = useState('')
    const [Age, setAge] = useState('')
    const [Gender, setGender] = useState('')
    const [City, setCity] = useState('')
    const [RaceEthnicity, setRaceEthnicity] = useState('')
    const [StudyField, setStudyField] = useState('')
    const [LatestEducation, setLatestEducation] = useState('')
    const [ResumeLocation, setResumeLocation] = useState('')
    const [LocationPrefrence, setLocationPrefrence] = useState('')
    const [PublicationDetails, setPublicationDetails] = useState('')
    const [ResearchExp, setResearchExp] = useState('')
    const [Skill, setSkill] = useState('')
    const [JobPref, setJobPref] = useState('')
    const handleSelectChange = (event) => {
      setGender(event.target.value);}
      var RegisterHandle = async (e) => {
        console.log(Email);
        console.log(Password);
        e.preventDefault()
        let config = { method: 'POST', FirstName:FirstName, LastName:LastName,Email:Email,Password:Password,Role:Role,Age:Age,Gender:Gender,City:City,RaceEthnicity:RaceEthnicity,StudyField:StudyField,LatestEducation:LatestEducation,ResumeLocation:ResumeLocation,
        LocationPrefrence:LocationPrefrence,PublicationDetails:PublicationDetails,ResearchExp:ResearchExp,Skill:Skill,JobPref:JobPref }

    try {
        await axios
           .post('https://mxp2278.uta.cloud/demo/Mailer/register.php', config)
          .then((response) => {
            console.log(response.data);
            
            // const dataString = response.data.toString();
            // const startIndex = dataString.indexOf('{"userData":{');
            // const endIndex = dataString.lastIndexOf('}') + 1;
            // const userData = JSON.parse(dataString.substring(startIndex, endIndex)).userData;
            // const role = userData.role;
            
            //setLogin(true);
            if (response.data['status']==0){
              // console.log("You entered the wrong password");
              alert('Registration Successful! Check your mail to activate your account.');
              navigate('/login');

                // window.location.reload();
            }
            else if (response.data['status']==1){
              alert('This email is already taken. Try Again!');
              // window.location.reload();
            }
            // if (response.data['status']==0){
            //   const role = response.data.user.Role;
            //   console.log(role);}
            //   if(role=="Academia")
            //       {
            //       //navigate('/Academia');
            //       setEmail(Email);
            //      // navigate('/Academia', { state: { email: response.data.user.Email } });
            //      navigate(`/Academia?email=${encodeURIComponent(Email)}`);
            //      //setmail(mail);
                  
            //       }
            //     else if(role=="URMCandidate")
            //      {
            //       navigate(`/candidate-dashboard?email=${encodeURIComponent(Email)}`);
            //      }
            //     else if(role=="DeiOfficer")
            //     {
            //       setEmail(Email);
            //       console.log("Entered Officer");
            //       navigate(`/dei-officer?email=${encodeURIComponent(Email)}`);
            //     }
            //     else if(role=="Admin")
            //     {
            //       navigate(`/admin?email=${encodeURIComponent(Email)}`);
            //     }
            // }
          })
          .catch((error) => {
            console.log('no authorization', error)
          })
      } catch (error) {
        setTimeout(() => {
          setError('Invalid credentials...')
        }, 10)
      }
    }

  return (
    <>
        <Header/>
        <div class="p-container">
            <h1>Candidate Registration Form</h1>   
            <Link to='/tips' style={{color:'black'}}>TIPS TO FOLLOW TO CREATE AN EFFECTIVE PROFILE</Link> 
            <form>
                <label for="firstName">First Name:</label>
                <input type="text" id="firstName" name="firstName" placeholder="Your First Name"
                onChange={(e) => setFirstName(e.target.value)}/>
                <label for="lastName">Last Name:</label>
                <input type="text" id="lastName" name="lastName" placeholder="Your Last Name"
                onChange={(e) => setLastName(e.target.value)}/>
                <label for="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Your Email Address"
                onChange={(e) => setEmail(e.target.value)}/>
                <label for="psw">Password</label>
                <input type="password" placeholder="Enter Password" name="psw" id="psw" required
                onChange={(e) => setPassword(e.target.value)}/>
                <label for="age">Age:</label>
                <input type="text" id="age" name="age" placeholder="Your Age"
                onChange={(e) => setAge(e.target.value)}/>
                <label for="gender">Gender:</label>
                <select  required name="gender" onChange={handleSelectChange}>
                <option hidden value=' ' disabled selected >Gender</option>
                <option >Male</option>
                <option>Female</option>
                <option>Other</option>
                </select>
                <label for="city">City:</label>
                <input type="text" id="city" name="city" placeholder="Your City name"
                onChange={(e) => setCity(e.target.value)}/>
                <label for="study">Race / Ethnicity</label>
                <input type="text" id="Race" name="Race" placeholder="Race / Ethnicity"
                onChange={(e) => setRaceEthnicity(e.target.value)}/>
                <label for="study">Job preference</label>
                <input type="text" id="job" name="job" placeholder="Job preference"
                onChange={(e) => setJobPref(e.target.value)}/>
                <label for="study">Job location preference</label>
                <input type="text" id="jobL" name="jobL" placeholder="Job location preference"
                onChange={(e) => setLocationPrefrence(e.target.value)}/>
                <label for="study">Research experience</label>
                <input type="text" id="research" name="research" placeholder="Research experience"
                onChange={(e) => setResearchExp(e.target.value)}/>
                <label for="study">Field of study</label>
                <input type="text" id="study" name="study" placeholder="Your field of study"
                onChange={(e) => setStudyField(e.target.value)}/>
                <label for="education">Education</label>
                <input type="text" id="education" name="education" placeholder="Education"
                onChange={(e) => setLatestEducation(e.target.value)}/>
                <label for="publ">Publication Details</label>
                <input type="text" id="publ" name="publ" placeholder="Publication Details"
                onChange={(e) => setPublicationDetails(e.target.value)}/>
                <label for="resume">Upload Resume:</label>
                <input type="file" id="resume" name="resume" accept=".pdf,.doc,.docx" required
                onChange={(e) => setResumeLocation(e.target.value)}/>
                {/* <input type="submit" value="Register"/> */}
                <button onClick={RegisterHandle}>Register</button>
            </form>
        </div>
    </>
  ); 
}

export default CandidateRegister;
