import '../styles/header.css'
import { Link } from "react-router-dom"

function Header() {
  const blog=()=> {
    window.open("http://axp2779.uta.cloud/")
  }

    return (
          <header class="header">
            <Link to='/home' class="logo">URM APPLICATION</Link>
            <nav class="nav-items">
            <Link to='/home'>Home</Link>
            <span onClick={blog}>Blog</span>
            <Link to='/about'>About Us</Link>
            <Link to='/services'>Services</Link>
            <Link to='/login'>Login/Register</Link>
            <Link to='/contact'>Contact Us</Link>
            </nav>
        </header>
    ); 
  }
  
export default Header;

