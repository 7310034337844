// https://jxs4138.uta.cloud/academia-register
import Header from "../components/Header"
import '../styles/header.css'
import '../styles/register.css'
import { Link } from "react-router-dom"
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

function AcademiaRegister() {
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [FirstName, setFirstName] = useState('')
    const [Email, setEmail] = useState('')
    const [Password, setPassword] = useState('')
    const [Role, setRole] = useState('')
    const [LogoLocation, setLogoLocation] = useState('')
    const [AboutUs, setAboutUs] = useState('')
    const [Research, setResearch] = useState('')
  const [addresses, setAddresses] = useState(['']);
  const [facultyMembers, setFacultyMembers] = useState([{ name: '', researchArea: '',email:'',courses:'' }]);


  const handleFacultyChange = (index, field, value) => {
    const newFacultyMembers = [...facultyMembers];
    newFacultyMembers[index][field] = value;
    setFacultyMembers(newFacultyMembers);
  };

  const handleAddFaculty = () => {
    setFacultyMembers([...facultyMembers, { name: '', researchArea: '' ,email:'',courses:''}]);
  };


  var RegisterHandle = async (e) => {
    console.log(Email);
    console.log(Password);
    e.preventDefault()
    let config = { method: 'POST', FirstName:FirstName,Email:Email,Password:Password,Role:Role,LogoLocation:LogoLocation,
    AboutUs:AboutUs,Research:Research,facultyMembers:facultyMembers }

try {
    await axios
       .post('https://mxp2278.uta.cloud/demo/Mailer/register.php', config)
      .then((response) => {
        console.log(response.data);
        
        // const dataString = response.data.toString();
        // const startIndex = dataString.indexOf('{"userData":{');
        // const endIndex = dataString.lastIndexOf('}') + 1;
        // const userData = JSON.parse(dataString.substring(startIndex, endIndex)).userData;
        // const role = userData.role;
        
        //setLogin(true);
        if (response.data['status']==0){
          // console.log("You entered the wrong password");
          alert('Registration Successful!');
          navigate('/login');

            // window.location.reload();
        }
        else if (response.data['status']==1){
          alert('This email is already taken. Try Again!');
          // window.location.reload();
        }
        // if (response.data['status']==0){
        //   const role = response.data.user.Role;
        //   console.log(role);}
        //   if(role=="Academia")
        //       {
        //       //navigate('/Academia');
        //       setEmail(Email);
        //      // navigate('/Academia', { state: { email: response.data.user.Email } });
        //      navigate(`/Academia?email=${encodeURIComponent(Email)}`);
        //      //setmail(mail);
              
        //       }
        //     else if(role=="URMCandidate")
        //      {
        //       navigate(`/candidate-dashboard?email=${encodeURIComponent(Email)}`);
        //      }
        //     else if(role=="DeiOfficer")
        //     {
        //       setEmail(Email);
        //       console.log("Entered Officer");
        //       navigate(`/dei-officer?email=${encodeURIComponent(Email)}`);
        //     }
        //     else if(role=="Admin")
        //     {
        //       navigate(`/admin?email=${encodeURIComponent(Email)}`);
        //     }
        // }
      })
      .catch((error) => {
        console.log('no authorization', error)
      })
  } catch (error) {
    setTimeout(() => {
      setError('Invalid credentials...')
    }, 10)
  }
}
 
  return (
    <>
        <Header/>
        <main>
            <div class="intro">
                <div class="r-container">
                  <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                <h1>Academia Registration Form</h1>
                <label for="Name">Name of the Institution</label>
                <input type="text" placeholder="Enter Name" name="name" id="name" required
                onChange={(e) => setFirstName(e.target.value)}/>
                <label for="email">Email</label>
                <input type="text" placeholder="Enter Email" name="email" id="email" required
                onChange={(e) => setEmail(e.target.value)}/>
                <label for="psw">Password</label>
                <input type="password" placeholder="Enter Password" name="psw" id="psw" required
                onChange={(e) => setPassword(e.target.value)}/>
                {/* <label for="psw-repeat">Confirm Password</label>
                <input type="password" placeholder="Confirm Password" name="psw-confirm" id="psw-confirm" required/> */}
                {/* <label for="address">Address</label>
                <input type="text" placeholder="Enter Address" name="address" id="address" required/> */}
                <label htmlFor="address">Role</label>
                <input type="text" placeholder="Enter Role" name="address" id="address" required
                onChange={(e) => setRole(e.target.value)}/>
                <label for="Name">About your institution</label>
                <input type="text" placeholder="About your Institution" name="institute" id="institute" required
                onChange={(e) => setAboutUs(e.target.value)}/>
                <label for="rw">About your Research Work</label>
                <input type="text" placeholder="About your Research work" name="rw" id="rw" required
                onChange={(e) => setResearch(e.target.value)}/>
                <label for="psw">Upload your logo</label>
                <input type="file" id="resume" name="resume" accept=".png,.jpg,.jpeg" required
                onChange={(e) => setLogoLocation(e.target.value)}/>
                <hr></hr>
            {facultyMembers.map((faculty, index) => (
              <div key={index}>
                <label htmlFor={`facultyName${index}`}>Faculty Name</label>
                <input
                  type="text"
                  placeholder="Enter Faculty Name"
                  name={`facultyName${index}`}
                  value={faculty.name}
                  onChange={(e) => handleFacultyChange(index, 'name', e.target.value)}
                  required
              />
                <label htmlFor={`facultyCourses${index}`}>Email</label>
                <input
                  type="text"
                  placeholder="Enter Email"
                  name={`facultyCourses${index}`}
                  value={faculty.email}
                  onChange={(e) => handleFacultyChange(index, 'email', e.target.value)}
                  required
                />
                <label htmlFor={`facultyResearchArea${index}`}>Research Area</label>
                <input
                  type="text"
                  placeholder="Enter Research Area"
                  name={`facultyResearchArea${index}`}
                  value={faculty.researchArea}
                  onChange={(e) => handleFacultyChange(index, 'researchArea', e.target.value)}
                  required
                />
                <label htmlFor={`facultyCourses${index}`}>Courses</label>
                <input
                  type="text"
                  placeholder="Enter Courses"
                  name={`facultyCourses${index}`}
                  value={faculty.courses}
                  onChange={(e) => handleFacultyChange(index, 'courses', e.target.value)}
                  required
                />
                
              </div>
            ))}

            <button type="button" onClick={handleAddFaculty}>
              Add More Faculty
            </button>
                <p>By creating an account you agree to our <a href="#">Terms & Privacy</a>.</p>            
                <button onClick={RegisterHandle}>Register</button>
            </div>
            <div class="container signin">
                <p>Already have an account? <Link to='/login'><a >Sign in</a></Link>.</p>
            </div>
    </div>
  </main>
    </>
  ); 
}

export default AcademiaRegister;
