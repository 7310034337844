import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from './views/Home'
import About from "./views/About"
import Services from "./views/Services"
import Login from "./views/Login"
import Register from "./views/Register"
import Contact from "./views/Contact"
import Bookmark from "./views/Bookmark"
import CandidateDashboard from "./views/CandidateDashboard"
import Feedback from "./views/Feedback"
import ForgotPassword from "./views/ForgotPassword"
import ManageRequest from "./views/ManageRequest"
import EditProfile from "./views/EditProfile"
import Admin from "./views/Admin"
import DEIOfficer from "./views/DEIOfficer"
import Academia from "./views/Academia"
import Chat from "./views/Chat"
import AcademiaRegister from "./views/AcademiaRegister"
import DEIOfficerRegister from "./views/DEIOfficerRegister"
import FeedbackC from "./views/Feedbackc"
import Chatc from "./views/Chatc"
import Feedbackd from "./views/Feedbackd"
import CandidateRegister from "./views/CandidateRegister"
import NewJobPost from "./views/NewJobPost"
import EditProfileAcademia from "./views/EditProfileAcademia"
import EditProfileDEI from "./views/EditProfileDEI"
import Tips from "./views/Tips"

function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" component={<Home />}>
          <Route index element={<Home />} />
          <Route path='home' element={<Home/>}/>
          <Route path='about' element={<About/>}/>
          <Route path='services' element={<Services/>}/>
          <Route path='login' element={<Login/>}/>
          <Route path='register' element={<Register/>}/>
          <Route path='contact' element={<Contact/>}/>
          <Route path='bookmark' element={<Bookmark/>}/>
          <Route path='chat' element={<Chat/>}/>
          <Route path='chatc' element={<Chatc/>}/>
          <Route path='feedback' element={<Feedback/>}/>
          <Route path='feedbackc' element={<FeedbackC/>}/>
          <Route path='feedbackd' element={<Feedbackd/>}/>
          <Route path='forgot-password' element={<ForgotPassword/>}/>
          <Route path='candidate-dashboard' element={<CandidateDashboard/>}/>
          <Route path='manage-request' element={<ManageRequest/>}/>
          <Route path='edit-profile' element={<EditProfile/>}/>
          <Route path='edit-profilea' element={<EditProfileAcademia/>}/>
          <Route path='edit-profiled' element={<EditProfileDEI/>}/>
          <Route path='admin' element={<Admin/>}/>
          <Route path='tips' element={<Tips/>}/>
          <Route path='dei-officer' element={<DEIOfficer/>}/>
          <Route path='academia' element={<Academia/>}/>
          <Route path='academia-register' element={<AcademiaRegister/>}/>
          <Route path='candidate-register' element={<CandidateRegister/>}/>
          <Route path='dei-register' element={<DEIOfficerRegister/>}/>
          <Route path='new-job-post' element={<NewJobPost/>}/>
        </Route>
      </Routes>
    </BrowserRouter>

  )
}

export default App
