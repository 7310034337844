// https://jxs4138.uta.cloud/manage-request
import AdminHeader from '../components/AdminHeader';
import '../styles/header.css'
import '../styles/Candidatedashboard.css'
import '../styles/profile-edit.css'
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'

function ManageRequest() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Email = queryParams.get('email');
  return (
    <>
        <AdminHeader Email={Email}/>
        <div class="p-container">
            <h1>Manage Request</h1>
            <form>
                <label for="gender">John Doe:</label>
                <div style={{display:'flex'}}>
                  <button style={{margin:'8px'}}>Approve</button>
                  <button style={{margin:'8px'}}>Reject</button>
                </div>
                <label for="gender">Markus Saint</label>
                <div style={{display:'flex'}}>
                  <button style={{margin:'8px'}}>Approve</button>
                  <button style={{margin:'8px'}}>Reject</button>
                </div>

                <label for="gender">Angel joli</label>
                <div style={{display:'flex'}}>
                  <button style={{margin:'8px'}}>Approve</button>
                  <button style={{margin:'8px'}}>Reject</button>
                </div>

                <label for="gender">Hayathi kaith</label>
                <div style={{display:'flex'}}>
                  <button style={{margin:'8px'}}>Approve</button>
                  <button style={{margin:'8px'}}>Reject</button>
                </div>
            </form>
        </div>
    </>
  ); 
}

export default ManageRequest;
