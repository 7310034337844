// https://jxs4138.uta.cloud/edit-profiled
import DEIHeader from '../components/DeiOfficerHeader';
import '../styles/header.css'
import '../styles/Candidatedashboard.css'
import '../styles/profile-edit.css'
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'

function EditProfileDEI() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Email = queryParams.get('email');
  const [FirstName, setFirstName] = useState('');
  const [LastName, setLastName] = useState('');
  const [Password, setPassword] = useState('')
  const [error, setError] = useState('')

  var submitData = async (e) => {
    e.preventDefault();
    let config = { method: 'POST', FirstName: FirstName, Email:Email, Password : Password,  LastName: LastName}

try {
    await axios
       .put(' https://mxp2278.uta.cloud/demo/dei_officer_edit_profile.php', config)
      .then((response) => {
        console.log("submission block");
        console.log(response.data);
        
        // const dataString = response.data.toString();
        // const startIndex = dataString.indexOf('{"userData":{');
        // const endIndex = dataString.lastIndexOf('}') + 1;
        // const userData = JSON.parse(dataString.substring(startIndex, endIndex)).userData;
        // const role = userData.role;
        
        //setLogin(true);
        if (response.data['status']==1){
          console.log("Data Updated Successfully");
          alert('Data Updated Successfully');
          window.location.reload();
        }
        if (response.data['status']==0){
          alert('Failed to update the data. Try Again!');
          console.log("Job Posting failed");
        }
      })
      .catch((error) => {
        console.log('no authorization', error)
      })
  } catch (error) {
    setTimeout(() => {
      setError('Invalid credentials...')
    }, 10)
  }
}
  return (
    <>
        <DEIHeader Email={Email}/>
        <div class="p-container">
            <h1>Edit Profile</h1>    
            <form>
            <label for="fname">Name </label>
                <input type="text" placeholder="Enter First Name" name="fname" id="fname" required
                onChange={(e) => setFirstName(e.target.value)}/>
                <label for="lname">Enter Last Name</label>
                <input type="text" placeholder="Enter Last Name" name="lname" id="lname" required
                onChange={(e) => setLastName(e.target.value)}/>
                <label for="pwd">Enter New Password</label>
                <input type="password" placeholder="Enter Password" name="pwd" id="pwd" required
                onChange={(e) => setPassword(e.target.value)}/>
                <button onClick={submitData}>Save Changes</button>
            </form>
        </div>
    </>
  ); 
}

export default EditProfileDEI;
