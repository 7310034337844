// https://jxs4138.uta.cloud/services
import Header from "../components/Header"
import '../styles/header.css'

function Services() {
  return (
    <>
        <Header/>
        <main>
        <div class="intro">
            <div class="about-me">
            <div class="about-me-text">
                <h2>Services Provided</h2>
                <p style={{textAlign:'left'}}>1. Homepage: Gives a summary of the features and services offered by the website. Allows the users to navigate to Login/Registration page </p>
                <p style={{textAlign:'left'}}>2. Registration and Login: Users can register for an account by selecting the appropriate role and login using their email address and password.</p>
                <p style={{textAlign:'left'}}>3. Academia Dashboard: Academics maintains a list of potential candidates, faculty and job postings. Academia can search for applicants, bookmark their profiles, provide feedback to the student and contact them if required. They can add new job postings.</p>
                <p style={{textAlign:'left'}}>4. URM Candidate Dashboard: The candidate can maintain his profile, add Resume, educational history, location preferences, etc. In the URM candidate dashboard, the candidate can see all the job postings he applied for, see if there are any new job postings available based on his preference, filter out the postings, contact the academia, get notified if any academia was interested in his profile and edit any information he wants to. </p>
                <p style={{textAlign:'left'}}>5. DEI Officer Dashboard: The DEI Officer can view all the job postings, see all the postings that align with their initiative and goal, and see the number of students that applied for 2 particular postings. Approve or reject a job posting if it does not align with their goal. Introduce new initiatives and activities. Edit their profile information if required. </p>
                <p style={{textAlign:'left'}}>6. Admin Dashboard: The admin generates reports depending upon the data available from all user roles, provides access to newly registered users, receives feedback from all the users and provides assistance if any user requires it.  </p>
                <p style={{textAlign:'left'}}>7. Message/Chat: The candidate can message the academia also the academia can message the candidate.</p>
            </div>
            </div>
        </div>
    </main>
    </>
  ); 
}

export default Services;
