// https://jxs4138.uta.cloud/dei-register
import Header from "../components/Header"
import '../styles/header.css'
import '../styles/register.css'
import { Link } from "react-router-dom"

import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

function DEIOfficerRegister() {
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [FirstName, setFirstName] = useState('')
  const [LastName, setLastName] = useState('')
    const [Email, setEmail] = useState('')
    const [AcademiaEmail, setAcademiaEmail] = useState('')
    const [Password, setPassword] = useState('')
    const [Role, setRole] = useState('')
    const [Goal, setGoal] = useState('')
    const [Location, setLocation] = useState('')
    const [Prefrence, setPrefrence] = useState('')
    const [PrefCategory, setPrefCategory] = useState('')

    var RegisterHandle = async (e) => {
      console.log(Email);
      console.log(Password);
      e.preventDefault()
      let config = { method: 'POST', FirstName:FirstName,
      LastName:LastName,
      Email:Email,
    AcademiaEmail:AcademiaEmail,
      Password:Password,
      Role:Role,
      Goal:Goal,
      Location:Location,
      PrefCategory:PrefCategory,
      Prefrence:Prefrence }
  
  try {
      await axios
         .post('https://mxp2278.uta.cloud/demo/Mailer/register.php', config)
        .then((response) => {
          console.log(response.data);
          
          // const dataString = response.data.toString();
          // const startIndex = dataString.indexOf('{"userData":{');
          // const endIndex = dataString.lastIndexOf('}') + 1;
          // const userData = JSON.parse(dataString.substring(startIndex, endIndex)).userData;
          // const role = userData.role;
          
          //setLogin(true);
          if (response.data['status']==0){
            // console.log("You entered the wrong password");
            alert('Registration Successful!');
            navigate('/login');
  
              // window.location.reload();
          }
          else if (response.data['status']==1){
            alert('This email is already taken. Try Again!');
            // window.location.reload();
          }
          // if (response.data['status']==0){
          //   const role = response.data.user.Role;
          //   console.log(role);}
          //   if(role=="Academia")
          //       {
          //       //navigate('/Academia');
          //       setEmail(Email);
          //      // navigate('/Academia', { state: { email: response.data.user.Email } });
          //      navigate(`/Academia?email=${encodeURIComponent(Email)}`);
          //      //setmail(mail);
                
          //       }
          //     else if(role=="URMCandidate")
          //      {
          //       navigate(`/candidate-dashboard?email=${encodeURIComponent(Email)}`);
          //      }
          //     else if(role=="DeiOfficer")
          //     {
          //       setEmail(Email);
          //       console.log("Entered Officer");
          //       navigate(`/dei-officer?email=${encodeURIComponent(Email)}`);
          //     }
          //     else if(role=="Admin")
          //     {
          //       navigate(`/admin?email=${encodeURIComponent(Email)}`);
          //     }
          // }
        })
        .catch((error) => {
          console.log('no authorization', error)
        })
    } catch (error) {
      setTimeout(() => {
        setError('Invalid credentials...')
      }, 10)
    }
  }

  return (
    <>
        <Header/>
        <main>
            <div class="intro">
                <div class="r-container">
                <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                
                <h1>DEI officer Registration Form</h1>
                <label for="fname">Enter First Name </label>
                <input type="text" placeholder="Enter First Name" name="fname" id="fname" required
                onChange={(e) => setFirstName(e.target.value)} />
                <label for="lName">Enter Last Name </label>
                <input type="text" placeholder="Enter Last Name" name="lname" id="lname" required
                onChange={(e) => setLastName(e.target.value)}/>
                <label for="email">Email</label>
                <input type="text" placeholder="Enter Email" name="email" id="email" required
                onChange={(e) => setEmail(e.target.value)}/>
                <label for="psw">Password</label>
                <input type="password" placeholder="Enter Password" name="psw" id="psw" required
                onChange={(e) => setPassword(e.target.value)}/>
                <label for="emaila">Email of your Academia</label>
                <input type="text" placeholder="Enter Email of your Academia" name="emaila" id="emaila" required
                onChange={(e) => setAcademiaEmail(e.target.value)}/>
                {/* <label for="psw">Password</label>
                <input type="password" placeholder="Enter Password" name="psw" id="psw" required
                onChange={(e) => setPassword(e.target.value)}/> */}
                <label for="role">Role</label>
                <input type="text" placeholder="Enter Role" name="role" id="role" required
                onChange={(e) => setRole(e.target.value)}/>
                <label for="goals">Role</label>
                <input type="text" placeholder="Enter Goals" name="goals" id="goals" required
                onChange={(e) => setGoal(e.target.value)}/>
                <label for="study">Location</label>
                <input type="text" id="jobL" name="jobL" placeholder="Location"
                onChange={(e) => setLocation(e.target.value)}/>
                <label for="studyi">Preference</label>
                <input type="text" id="jobLi" name="jobLi" placeholder="Preference"
                onChange={(e) => setPrefrence(e.target.value)}/>
                <label for="studyr">Preferential Category</label>
                <input type="text" id="jobLr" name="jobLr" placeholder="Preferential Category"
                onChange={(e) => setPrefCategory(e.target.value)}/>
                <p>By creating an account you agree to our <a href="#">Terms & Privacy</a>.</p>            
                <button onClick={RegisterHandle}>Register</button>
            </div>
            <div class="container signin">
                <p>Already have an account? <Link to='/login'><a >Sign in</a></Link>.</p>
            </div>
    </div>
  </main>
    </>
  ); 
}

export default DEIOfficerRegister;
