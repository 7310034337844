// https://jxs4138.uta.cloud/login
import Header from "../components/Header"
import '../styles/header.css'
import '../styles/login.css'
import { Link } from "react-router-dom"
import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Login() {
  const [Email, setEmail] = useState('')
    const [Password, setPassword] = useState('')
    console.log(Email);
    console.log(Password);
    const [error, setError] = useState('')
    const [isLoggedin, setLogin] = React.useState(false)
    console.log(Email);
    const navigate = useNavigate()
    var loginHandle = async (e) => {
        console.log(Email);
        console.log(Password);
        e.preventDefault()
        let config = { method: 'POST', Email: Email, Password: Password }

    try {
        await axios
           .post('https://mxp2278.uta.cloud/demo/login.php', config)
          .then((response) => {
            console.log(response.data);
            
            // const dataString = response.data.toString();
            // const startIndex = dataString.indexOf('{"userData":{');
            // const endIndex = dataString.lastIndexOf('}') + 1;
            // const userData = JSON.parse(dataString.substring(startIndex, endIndex)).userData;
            // const role = userData.role;
            
            //setLogin(true);
            if (response.data['status']==1){
              console.log("You entered the wrong password");
              alert('Wrong Password! Try again');
          // window.location.reload();
            }
            if (response.data['status']==2){
              alert('This email isn\'t registered');
              window.location.reload();
            }
            if (response.data['status']==0){
              const role = response.data.user.Role;
              console.log(role);
              if(role=="Academia")
                  {
                  //navigate('/Academia');
                  setEmail(Email);
                 // navigate('/Academia', { state: { email: response.data.user.Email } });
                 navigate(`/Academia?email=${encodeURIComponent(Email)}`);
                 //setmail(mail);
                  
                  }
                else if(role=="URMCandidate")
                 {
                  navigate(`/candidate-dashboard?email=${encodeURIComponent(Email)}`);
                 }
                else if(role=="DeiOfficer")
                {
                  setEmail(Email);
                  console.log("Entered Officer");
                  navigate(`/dei-officer?email=${encodeURIComponent(Email)}`);
                }
                else if(role=="Admin")
                {
                  navigate(`/admin?email=${encodeURIComponent(Email)}`);
                }
            }
          })
          .catch((error) => {
            console.log('no authorization', error)
          })
      } catch (error) {
        setTimeout(() => {
          setError('Invalid credentials...')
        }, 10)
      }
    }

  return (
    <>
        <Header/>
        <main>
        <div class="intro">
        <form action="" className="form" onSubmit={loginHandle}>
            <h2 class="centerCont">LOGIN FORM</h2>
            <div class="login-container">
                <label for="uname"><b>Username</b></label>
                <input type="text" placeholder="Enter Username" name="uname" required
                onChange={(e) => setEmail(e.target.value)}/>
                <label for="psw"><b>Password</b></label>
                <input type="password" placeholder="Enter Password" name="psw" required
                onChange={(e) => setPassword(e.target.value)}/>
                <button type="submit">Login</button>
                <Link to='/forgot-password'><a href="forgotpassword.html" id="forgotPasswordLink">Forgot Password?</a></Link>
                <br/><br/>
                <label>
                    <input type="checkbox" checked="checked" name="remember"/> Remember me
                </label>
            </div>
        <div class="container register">
        <Link to='/register'><p>New User?&nbsp;&nbsp;&nbsp;&nbsp;<a>Register</a></p></Link>
        </div>
        </form>
        </div>
        </main>
    </>
  ); 
  
          
    }
export default Login;
