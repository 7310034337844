// https://jxs4138.uta.cloud/feedbackc
import CandidateHeader from '../components/CandidateHeader';
import '../styles/header.css'
import '../styles/Candidatedashboard.css'
import '../styles/feedback.css'
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function FeedbackC() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Email = queryParams.get('email');
  const navigate = useNavigate()
  var SendFeedback = async () => {
    alert('Thank you for the feedback.');
              // navigate('/home');
}
  return (
    <>
        <CandidateHeader Email={Email}/>
        <div class="msg">
        <h1>FEEDBACK FORM</h1>
        <div class="fe-container">
        <form id="feedbackForm" action="#" method="post">
        <textarea id="feedback" name="feedback" rows="10" placeholder="Please let us know your website experience..." required></textarea>

        <button onClick={SendFeedback}>Send Feedback</button>
        </form>
        </div>
        </div>
    </>
  ); 
}

export default FeedbackC;
