import '../styles/header.css'
import { Link } from "react-router-dom"
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'

function DEIHeader({ Email }) {
    return (
          <header class="header">
            <Link to='/home' class="logo">URM APPLICATION</Link>
            <Link to={`/dei-officer?email=${encodeURIComponent(Email)}`}class="logo">DEI OFFICER</Link>
            <nav class="nav-items">
            <Link>
            </Link>
            <Link to={`/feedbackd?email=${encodeURIComponent(Email)}`}>Feedback</Link>
            <Link to={`/edit-profiled?email=${encodeURIComponent(Email)}`}>Profile</Link>
            </nav>
        </header>
    ); 
  }
  
export default DEIHeader;
