import React, { useState, useEffect } from "react";
import CandidateHeader from '../components/CandidateHeader';
import '../styles/header.css';
import '../styles/Candidatedashboard.css';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const TableComponentjob = ({ data }) => {
  return (
    <div className="tableDiv">
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Location</th>
            <th>Position</th>
            <th>Apply Here</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.Title}</td>
              <td>{item.Description}</td>
              <td>{item.Location}</td>
              <td>{item.Position}</td>
              <td><button>Apply</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TableComponentjobApplied = ({ data }) => {
  return (
    <div className="tableDiv">
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Position</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.Title}</td>
              <td>{item.Description}</td>
              <td>{item.Position}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

function CandidateDashboard() {
  const [responseData, setResponseData] = useState([]);
  const [responseDataApply, setResponseDataApply] = useState([]);
  const [error, setError] = useState('')
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Email = queryParams.get('email');

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredAppliedJobs = responseDataApply.filter((job) =>
    job.Title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  var joblist = async () => {
    let config = { method: 'POST', Email: Email }

    try {
      const response = await axios.post('https://mxp2278.uta.cloud/demo/urm_candidate_jobs_to_apply.php', config);
      const dataArray = Object.values(response.data);
      setResponseData(dataArray);
    } catch (error) {
      console.log('no authorization', error)
    }
  }

  var joblistApplied = async () => {
    let config = { method: 'POST', Email: Email }

    try {
      const response = await axios.post('https://mxp2278.uta.cloud/demo/urm_candidate_applied_job_postings.php', config);
      const dataArray = Object.values(response.data);
      setResponseDataApply(dataArray);
    } catch (error) {
      console.log('no authorization', error)
    }
  }

  useEffect(() => {
    joblist();
    joblistApplied();
  }, []);

  return (
    <>
      <CandidateHeader Email={Email} />
      <main>
        <div className="search-bar">
          <div className="search-input div1">
            <input
              type="text"
              placeholder="Search applied jobs based on title.."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="urmtables">
          <h2>Applied Jobs</h2>
          <TableComponentjobApplied data={filteredAppliedJobs} />
          <br />
          <h2>New Job Postings</h2>
          <TableComponentjob data={responseData} />
        </div>
      </main>
    </>
  );
}

export default CandidateDashboard;
