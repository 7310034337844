// https://jxs4138.uta.cloud/edit-profilea
import BookmarkHeader from '../components/BookmarkHeader';
import '../styles/header.css'
import '../styles/Candidatedashboard.css'
import '../styles/profile-edit.css'
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'


function EditProfileAcademia() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Email = queryParams.get('email');
  const [error, setError] = useState('')
  const [Name, setName] = useState('')
  const [FirstName, setFirstName] = useState('');
  const [AAboutUs, setAAboutUs] = useState('');
  const [LogoLocation, setLogoLocation] = useState('')
  const [LogoLocationnew, setLogoLocationnew] = useState('')
  const [Password, setPassword] = useState('')
  const [Passwordnew, setPasswordnew] = useState('')
  const [existingData,setResponseData]=useState('')
    // const [Password, setPassword] = useState('')
  //   const [LogoLocation, setEmail] = useState('')
    const [AboutUs, setAboutUs] = useState('')
//     const { Email } = useParams();
      console.log(Email);
    const fetchExistingData = async () => {
        try {
          const response = await axios.post('https://mxp2278.uta.cloud/demo/academia_details.php', { Email });
          console.log(response.data);
          setResponseData(response.data);
         // const existingData =  response.data;; // Assuming the response is an object with existing data
          // Set the existing data in component state
          console.log(existingData);
          setFirstName(response.data[0].FirstName|| '');
          setAAboutUs(response.data[0].AboutUs || '');
          setLogoLocation(response.data[0].LogoLocation || '');
          setPassword(response.data[0].Password || '');
          // Set other state variables for other fields as needed
        } catch (error) {
          console.error('Error fetching existing data:', error);
        }
      };

      var submitData = async (e) => {
        e.preventDefault();
        let config = { method: 'POST', FirstName: Name, Email:Email, Password : Passwordnew,  LogoLocation : LogoLocationnew, AboutUs:AboutUs}
    
    try {
        await axios
           .put('https://mxp2278.uta.cloud/demo/academia_edit_profile.php', config)
          .then((response) => {
            console.log("submission block");
            console.log(response.data);
            
            // const dataString = response.data.toString();
            // const startIndex = dataString.indexOf('{"userData":{');
            // const endIndex = dataString.lastIndexOf('}') + 1;
            // const userData = JSON.parse(dataString.substring(startIndex, endIndex)).userData;
            // const role = userData.role;
            
            //setLogin(true);
            if (response.data['status']==1){
              console.log("Data Updated Successfully");
              alert('Data Updated Successfully');
              window.location.reload();
            }
            if (response.data['status']==0){
              alert('Failed to update the data. Try Again!');
              console.log("Job Posting failed");
            }
          })
          .catch((error) => {
            console.log('no authorization', error)
          })
      } catch (error) {
        setTimeout(() => {
          setError('Invalid credentials...')
        }, 10)
      }
    }

    
 useEffect(() => {
  // Your function will be executed when the component is mounted
  //  editProfile();
   fetchExistingData();
  
}, []);
  return (
    
    <>
        <BookmarkHeader Email={Email}/>

        <div class="p-container">
            <h1>Edit Profile</h1>    
            <form>
                <label for="Name">Name of the institution</label>
                <input type="text"  name="name" id="name" 
                 placeholder={FirstName}
                onChange={(e) => setName(e.target.value)}/>
                <label for="ll">LogoLocation</label>
                <input type="text"  name="ll" id="ll" 
                placeholder={LogoLocation}
                onChange={(e) => setLogoLocationnew(e.target.value)}/>
                <label for="pwd">Password</label>
                <input type="text"  name="pwd" id="pwd" 
                 placeholder={Password}
                onChange={(e) => setPasswordnew(e.target.value)}/>
                <label for="abus">About Us</label>
                <input type="text"  name="abus" id="abus" 
                 placeholder={AAboutUs}
                onChange={(e) => setAboutUs(e.target.value)}/>
                
                {/* <label for="Name">About your institution</label>
                <input type="text" placeholder="About your institution" name="institute" id="institute" required/> */}
                {/* <label for="psw">Upload your logo</label>
                <input type="file" id="resume" name="resume" accept=".png,.jpg,.jpeg" required/> */}
                <button type="submit" onClick={submitData}>Save Changes</button>
            </form>
        </div>
    </>
  ); 
}

export default EditProfileAcademia;
