import Header from '../components/Header';
import '../styles/header.css'
import '../styles/Candidatedashboard.css'
import '../styles/chat.css'

function Tips() {
  return (
    <>
        <Header/>
        <main>
    <div class="msg">
      <h1>Tips to follow to create an effective profile</h1>
  
  <div class="c-container">
        <ul id="messageList">
            <li>Make sure you attach your latest resume</li>
            <li>Provide your accomplishments and certifications</li>
            <li>Highlight your skills</li>
            <li>Mention your research papers/publications</li>
            <li>Provide your location & job preferences</li>
        </ul>
    </div>
  </div>

  </main>
    </>
  ); 
}

export default Tips;
