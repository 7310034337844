import '../styles/header.css'
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom';


function AdminHeader({ Email }) {
  
    return (
          <header class="header">
            <Link to='/home' class="logo">URM APPLICATION</Link>
            <Link to={`/admin?email=${encodeURIComponent(Email)}`} class="logo">URM ADMIN</Link>
            <nav class="nav-items">
            {/* <Link>
            <select id="notification">
                <option value="all" disabled selected>Notifications</option>
                <option value="all">5 new Job postings</option>
                <option value="qualified">ABC academia bookmarked your profile</option>
                <option value="qualified">DEF academia bookmarked your profile</option>
                <option value="qualified">GHI academia bookmarked your profile</option>
                <option value="qualified">JKL academia bookmarked your profile</option>
                <option value="qualified">MNO academia bookmarked your profile</option>
                <option value="qualified">PQR academia bookmarked your profile</option>
            </select>
            </Link> */}
            <Link to={`/manage-request?email=${encodeURIComponent(Email)}`}>Manage Request</Link>
            </nav>
        </header>
    ); 
  }
  
export default AdminHeader;
