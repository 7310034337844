// https://jxs4138.uta.cloud/admin
import AdminHeader from '../components/AdminHeader';
import '../styles/header.css'
import '../styles/Candidatedashboard.css'
import '../styles/admin.css'
import Bargraph from '../images/Bargraph.png'
import PieRechartComponent from '../components/PieChart';
import BarChartComponent from '../components/BarChart';
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom';

function Admin() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Email = queryParams.get('email');
  return (
    <>
        <AdminHeader Email={Email}/>
        <div class="div-container">
    <div class="div1">
        <section id="users">
        <h2>Total Registered Users</h2>
        <p>Total Registered Users: <span id="totalUsers">3</span></p>
        <p>There are 3 registered users in the system.</p>
    </section>
    <section id="jobpostings">
        <h2>Job Postings</h2>
        <p>Total Job Postings: <span id="totalJobPostings">0</span></p>
        <p>Currently, there are 5 job postings available.</p>
    </section>
    <section id="candidates">
        <h2>Candidates Applied</h2>
        <p>Total Candidates Applied: <span id="totalCandidatesApplied">0</span></p>
        <p>2 candidates have applied for various job positions.</p>
    </section>
    
    <script>
    </script></div>
    <div class="div2">
        <div class="div3">
            <BarChartComponent/>
        </div>
        <div class="div4">
            <PieRechartComponent/>
        </div>
        <div class="div3">
            <BarChartComponent type2/>
        </div>
        <div class="div4">
            <table>
                <thead>
                  <tr>
                    <th>No of candidates</th>
                    <th>Roles</th>
                    <th>Academia</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>AI</td>
                    <td>mxp@mavs.uta.edu</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>WDM</td>
                    <td>mxp@mavs.uta.edu</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>PhD</td>
                    <td>mxp@mavs.uta.edu</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Manager</td>
                    <td>mxp@mavs.uta.edu</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>UI/UX Developer</td>
                    <td>mxp@mavs.uta.edu</td>
                  </tr>
                </tbody>
              </table>
        </div>
    </div>
    <br/>
  </div>  
    </>
  ); 
}

export default Admin;
