import React from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis } from 'recharts';
import { useState, useEffect} from "react"
// import axios from 'axios';
const BarChartComponent = (type2) => {
//   const [details, getdetailss]=useState([])
//     const getdetails = () => {
//         axios.get('http://localhost:8000/bar-chart.php').then((response)=>{
//             getdetailss(response.data)
//         }).catch(error=>console.error('Error'))
//     }
// 	useEffect(()=> {
//         getdetails();
//     },[]);
const details=[['PostDoc',1],['PhD',2],['Faculty',1],]
const details1=[['Applied',3],['Not Applied',6],]

const data = details?.map(data => {
  return (
    {roles:data[0],candidates:data[1]}
  );
})
const data2 = details1?.map(data => {
  return (
    {roles:data[0],candidates:data[1]}
  );
})
const renderCustomizedLabel = ({
  x, y, name
}) => {
  return (
    <text x={x} y={y} fill="black" textAnchor="end" dominantBaseline="central">
      {name}
    </text>
  );
};
return (
<BarChart width={250} height={250} data={type2? data2:data} label={renderCustomizedLabel}>
    <Bar dataKey="candidates" fill="#8faed9" />
    <CartesianGrid stroke="#ccc" />
    <XAxis dataKey="roles" />
    <YAxis dataKey="candidates" />
  </BarChart>
);
}
  
export default BarChartComponent;