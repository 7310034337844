import '../styles/header.css'
import { Link } from "react-router-dom"
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'

function CandidateHeader({ Email }) {
  console.log(Email);
    return (
          <header class="header">
            <Link to='/home' class="logo">URM APPLICATION</Link>
            <Link to={`/candidate-dashboard?email=${encodeURIComponent(Email)}`} class="logo">URM Candidate</Link>
            <nav class="nav-items">
            {/* <Link>
            <select id="notification">
                <option value="all" disabled selected>Notifications</option>
                <option value="all">5 new Job postings</option>
                <option value="qualified">ABC academia bookmarked your profile</option>
                <option value="qualified">DEF academia bookmarked your profile</option>
                <option value="qualified">GHI academia bookmarked your profile</option>
                <option value="qualified">JKL academia bookmarked your profile</option>
                <option value="qualified">MNO academia bookmarked your profile</option>
                <option value="qualified">PQR academia bookmarked your profile</option>
            </select>
            </Link> */}
            <Link to={`/chatc?email=${encodeURIComponent(Email)}`}>Chat</Link>
            <Link to={`/feedbackc?email=${encodeURIComponent(Email)}`}>Feedback</Link>
            <Link to={`/edit-profile?email=${encodeURIComponent(Email)}`}>Profile</Link>
            </nav>
        </header>
    ); 
  }
  
export default CandidateHeader;
