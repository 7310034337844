// https://jxs4138.uta.cloud/home
import Header from "../components/Header"
import '../styles/header.css'
import {Link} from 'react-router-dom'
function Home() {
  return (
    <>
        <Header/>
        <main>
            <div class="intro">
            <div class="introDiv">
            <h1>URM APPLICATION</h1>
            <p>This is a URM Application that helps candidates to apply for PHD Studies, Postdoc positions, and faculty positions.</p>
            </div>
            </div>
            {/* <Link to='/candidate-dashboard' style={{color:'black', marginLeft:'10px'}}>Candidate Dashboard</Link>
            <Link to='/academia' style={{color:'black', marginLeft:'10px'}}>Academia</Link>
            <Link to='/admin' style={{color:'black', marginLeft:'10px'}}>Admin</Link>
            <Link to='/dei-officer' style={{color:'black', marginLeft:'10px'}}>DEI officer</Link> */}
        </main>
    </>
  ); 
}

export default Home;
