// https://jxs4138.uta.cloud/bookmark
import BookmarkHeader from '../components/BookmarkHeader';
import '../styles/header.css'
import '../styles/Candidatedashboard.css'
import '../styles/URMAcademia.css'
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'

function Bookmark() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const Email = queryParams.get('email');

    const details=[['John Doe','PHD','...'],['John Doe','PHD','...'],['John Doe','PHD','...'],['John Doe','PHD','...'],['John Doe','PHD','...'],['John Doe','PHD','...']]
  return (
    <>
        <BookmarkHeader Email={Email}/>
        <main>
            <br/><br/>
            <div class="table-container">
            <p>List of bookmark candidates</p>
            <div class="tableDiv"><table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Position Applied</th>
                    <th>Info</th>
                </tr>
                </thead>
                <tbody>
                {details.map(data => {
                    return (
                    <tr>
                        <td>{data[0]}</td>
                        <td>{data[1]}</td>
                        <td>{data[2]}</td>
                    </tr>
                    );
                })}
                </tbody>
            </table></div>
            </div>
        </main>
    </>
  ); 
}

export default Bookmark;
