import Header from "../components/Header"
import '../styles/header.css'
import '../styles/contact.css'
import { useNavigate } from 'react-router-dom'

function Contact() {
  const navigate = useNavigate()
  var SendFeedback = async () => {
    alert('Thank you for the feedback. You will soon hear from us');
              navigate('/home');
}

  return (
    <>
        <Header/>
        <main>
    <div class="intro">
      <div class="contact-container">
        <div class="content">
          <div class="left-side">
            <div class="address details">
              <i class="ad-alt"></i>
              <div class="topic">Address</div>
              <div class="text-one">404 E Border St</div>
              <div class="text-two">Arlington, Texas</div>
            </div>
    
            <div class="phone details">
              <i class="pd-alt"></i>
              <div class="topic">Phone</div>
              <div class="text-one">+1 (986) 555-1122</div>
              <div class="text-two">+1 (999) 343-1234</div>
            </div>
    
            <div class="email details">
              <i class="pd-email"></i>
              <div class="topic">Email</div>
              <div class="text-one">urmapplication@gmail.com</div>
              <div class="text-two">urm.info@gmail.com</div>
            </div>
          </div>
    
          <div class="right-side">
            <div class="topic-text">Contact Us</div>
            <p>You can send us a message from this page if you need any help from us or if you have any questions about our platform. We are happy to assist you.</p>
            <form action="#">
              <div class="input-box">
                <input type="text" placeholder="Enter your name"/>
              </div>
              <div class="input-box">
                <input type="text" placeholder="Enter your email"/>
              </div>
              <div class="input-box message-box">
                <textarea id="subject" placeholder="Any Queries..." style={{height:"100px"}}></textarea>
              </div>
              <div class="button">
                <button onClick={SendFeedback}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
    </>
  ); 
}

export default Contact;
