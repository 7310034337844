// https://jxs4138.uta.cloud/chatc
import CandidateHeader from '../components/CandidateHeader';
import '../styles/header.css'
import '../styles/Candidatedashboard.css'
import '../styles/chat.css'
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'

function Chatc() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Email = queryParams.get('email');
  return (
    <>
        <CandidateHeader Email={Email}/>
        <main>
    <div class="msg">
      <h1>CHAT</h1>
  
  <div class="c-container">
    <div class="search-box">
      <input type="text" id="searchInput" placeholder="search or start new chat"/>
      
      <div class="dropdown" id="dropdown">
        <ul id="messageList">
        </ul>
      </div>
    </div>
    </div>
  </div>

  <script src="Message.js"></script>
  </main>
    </>
  ); 
}

export default Chatc;
