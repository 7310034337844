// https://jxs4138.uta.cloud/academia
import BookmarkHeader from '../components/BookmarkHeader';
import '../styles/header.css'
import '../styles/Candidatedashboard.css'
import '../styles/URMAcademia.css'
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom';


const TableComponent = ({ data }) => {
  return (
    <div className="tableDiv">
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Courses</th>
            <th>Research Work</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.Name}</td>
              <td>{item.EmailID}</td>
              <td>{item.Courses}</td>
              <td>{item.ResearchWork}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

  );
};

const TableComponentjob = ({ data }) => {
  return (
    <div className="tableDiv">
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Location</th>
            <th>Position</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.Title}</td>
              <td>{item.Description}</td>
              <td>{item.Location}</td>
              <td>{item.Position}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

  );
};



function Academia() {
    const navigate = useNavigate();
    const [error, setError] = useState('')
    const [responseData, setResponseData] = useState([]);
    const [responseDatajob, setResponseDatajob] = useState([]);
    const [responseDatadesc, setResponseDatadesc] = useState([]);
    const [responseDatare, setResponseDatare] = useState([]);
    const [AAboutUs, setAAboutUs] = useState('');
    const val=null;
    const details=[['John Doe','PHD','...'],['John Doe','PHD','...'],['John Doe','PHD','...'],['John Doe','PHD','...'],['John Doe','PHD','...'],['John Doe','PHD','...']]
    const newJobPost = () => {
      navigate(`/new-job-post?email=${encodeURIComponent(Email)}`)
    }
    const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Email = queryParams.get('email');
  var storeData;
  console.log(Email);
  var facultyList = async (e) => {
    console.log(Email);
    let config = { method: 'POST', Email: Email}

try {
    await axios
       .post('https://mxp2278.uta.cloud/demo/faculty_details.php', config)
      .then((response) => {
        console.log(response.data);
       // storeData=response.data;
       //setResponseData(response.data);
       const dataArray = Object.values(response.data); // Convert response.data into an array
            setResponseData(dataArray);
        //  <TableComponent data={Object.values(response.data)} />
        
      })
      .catch((error) => {
        console.log('no authorization', error)
      })
  } catch (error) {
    setTimeout(() => {
      setError('Invalid credentials...')
    }, 10)
  }
}

var joblist = async (e) => {
  console.log(Email);
  console.log("JOB posting");
  let config = { method: 'POST', Email: Email}

try {
  await axios
     .post('https://mxp2278.uta.cloud/demo/job_postings_academia.php', config)
    .then((response) => {
      console.log(response.data);
     // storeData=response.data;
     //setResponseData(response.data);
     const dataArray = Object.values(response.data); // Convert response.data into an array
          setResponseDatajob(dataArray);
      //  <TableComponent data={Object.values(response.data)} />
      
    })
    .catch((error) => {
      console.log('no authorization', error)
    })
} catch (error) {
  setTimeout(() => {
    setError('Invalid credentials...')
  }, 10)
}
}

var academiaDetails = async (e) => {
  console.log(Email);
  //console.log("JOB posting");
  let config = { method: 'POST', Email: Email}

try {
  await axios
     .post('https://mxp2278.uta.cloud/demo/academia_details.php', config)
    .then((response) => {
      console.log(response.data);
      console.log("Abiut us")
     // storeData=response.data;
     //setResponseData(response.data);
     const dataArray = Object.values(response.data); // Convert response.data into an array
          setResponseDatadesc(dataArray);
          const aa=responseDatadesc['AboutUs'];
          setAAboutUs(response.data[0].AboutUs || '');
          console.log(aa);
          console.log("Abiut us")
          // const responsed = JSON.parse(responseDatadesc);
          // console.log(responsed)
    //       console.log('************');
          //console.log(responseDatadesc[0].AboutUs);
      //  <TableComponent data={Object.values(response.data)} />
      
    })
    .catch((error) => {
      console.log('no authorization', error)
    })
} catch (error) {
  setTimeout(() => {
    setError('Invalid credentials...')
  }, 10)
}
}

var academiaReArea = async (e) => {
  console.log(Email);
  //console.log("JOB posting");
  let config = { method: 'POST', Email: Email}

try {
  await axios
     .post('https://mxp2278.uta.cloud/demo/academia_research_area.php', config)
    .then((response) => {
      console.log(response.data);
      
     // storeData=response.data;
    //  //setResponseData(response.data);
     const dataArray = Object.values(response.data); // Convert response.data into an array
          setResponseDatare(dataArray);
          console.log("RA")
          console.log(responseDatare);
    //       console.log('************');
          //console.log(responseDatadesc[0].AboutUs);
      //  <TableComponent data={Object.values(response.data)} />
      
    })
    .catch((error) => {
      console.log('no authorization', error)
    })
} catch (error) {
  setTimeout(() => {
    setError('Invalid credentials...')
  }, 10)
}
}

const ExampleComponent = ({ data }) => {
  const [responseDatadesc, setResponseDatadesc] = useState(data);
  return (
    <div>
      <h2 style={{ fontSize: '20px' }}>Research Areas:</h2>
      <ul>
        {responseDatadesc.map((item, index) => (
          <li key={index} style={{ fontSize: '15px' }}>{item.Research}</li>
        ))}
      </ul>
    </div>
  );
};

useEffect(() => {
  // Your function will be executed when the component is mounted
  facultyList();
  joblist();
  academiaDetails();
  academiaReArea();
}, []);
console.log("End of ");
console.log(Email);
  return (
    <>
        <BookmarkHeader Email={Email}/>
        <main>
    <p class="heading" style={{marginLeft:"48px"}}></p>
    <div class="div1">
    <p class="content" >
      {/* {JSON.stringify(responseDatadesc[0].AboutUs).replace(/"/g, '')} */}
      {/* {JSON.stringify(responseDatadesc[0])} */}
     <p style={{ fontSize: '26px' }}> {JSON.stringify(AAboutUs).replace(/"/g, '')}</p>
    <ExampleComponent data={responseDatare} />
      </p></div>
    <br/><br/>
    <div class="search-bar">
      <div class="search-input div1">
        <input type="text" placeholder="Search canditate by name..."/>
      </div>
      <div class="div2">
        <select id="filter">
          <option value="all" disabled selected>Apply Filters</option>
          <option value="all">Name</option>
          <option value="qualified">Position</option>
          <option value="not-qualified">Info</option>
        </select>
      </div>
    </div>
    <div class="table-container">
      <p>Candidate list</p>
      <div class="tableDiv">
        <table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Position</th>
            <th>Info</th>
            <th>Bookmark</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>John Doe</td>
            <td>PHP</td>
            <td>...</td>
            <td><button onclick="bookmarkRow(this)">Bookmark</button></td>
            <td>Applied</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Alex</td>
            <td>PHP</td>
            <td>...</td>
            <td><button onclick="bookmarkRow(this)">Bookmark</button></td> 
            <td>Applied</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Amega</td>
            <td>PHP</td>
            <td>...</td>
            <td><button onclick="bookmarkRow(this)">Bookmark</button></td> 
            <td>Applied</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Rakus</td>
            <td>PHP</td>
            <td>...</td>
            <td><button onclick="bookmarkRow(this)">Bookmark</button></td> 
            <td>Applied</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Doe</td>
            <td>PHP</td>
            <td>...</td>
            <td><button onclick="bookmarkRow(this)">Bookmark</button></td>
            <td>Applied</td>
          </tr>
        </tbody>
      </table></div>
         
      <p>Faculty List</p>
      <TableComponent data={responseData} />
      <button class="add-postings-btn" onClick={newJobPost}>Add New Job Postings</button>
      <p>Job Postings</p>
      <TableComponentjob data={responseDatajob} />
      
    </div>
  
  </main>
    </>
  ); 
}

export default Academia;
